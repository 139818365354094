<template>
  <div>
      <v-row>
        <v-col cols="12" md="4">
          <p class='title'>Final Project Peer & Self Review</p>
        </v-col>
        <v-col  cols="12" md="4">
          <div class="d-flex">
            <span class="mt-2 mr-2">
              Select Review Phase
            </span>
            <v-btn-toggle
              title
              dense
              rounded
              v-model="reviewPhase"
            >
              <v-btn active-class="success"  @click="fetchReviewParameters(1)">
                Review-1
              </v-btn>
              <v-btn active-class="success"  @click="fetchReviewParameters(2)">
                Review-2
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>

      <v-divider />

      <div v-if="students.length > 0">
        <v-row class="d-flex justify-space-between">
          <p class="title ml-3 mt-4">
            Please evaluate yourself and your peers based on the Criteria below.
          </p>
          <p class="mt-5 mr-3" v-if="students && students[0] && !students[0].writeMode">
            <v-chip color="info" >Ratings cannot be updated. Disabled by the Admin</v-chip>
          </p>
        </v-row>

        <v-row v-for="(student, idx) in students" :key="student.id">
          <v-col cols="12" md="12">
            <v-card
              class="mx-auto"
            >
              <v-card-title class="d-flex flex-row justify-space-between">
                <span>{{idx + 1}}. {{student.name}} </span>
                <v-chip
                  class="ma-2"
                  color="secondary"
                  v-if="student.myself"
                >
                  Self Assessment
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="accent"
                  v-else
                >
                  Peer Assessment
                </v-chip>
              </v-card-title>
              <v-card-subtitle>
                {{student.rollNumber}}
              </v-card-subtitle>

              <v-card-text v-for="criteria in student.criterias" :key="criteria.criteriaId">
                <v-sheet
                  color="white"
                  elevation="1"
                  height="75"
                  class="d-flex flex-row justify-space-between pa-2"
                >
                  <span class="d-flex flex-column">
                    <span class="d-flex flex-column text-subtitle-1"> 
                      {{ criteria.criteriaName}}
                    </span>

                    <span class="d-flex flex-column text-caption"> 
                      {{ criteriaDescription(criteria.criteriaDescription, student.myself) }}
                    </span>
                  </span>

                  <span style="width: 150px" class="mt-4">
                    <v-rating
                      hover
                      length="5"
                      size="28"
                      dense
                      @input="setMarks(student, criteria, $event)"
                      :value="parseInt(criteria.score)"
                      :readonly='!student.writeMode'
                    >
                    </v-rating>
                  </span>
                </v-sheet>
              </v-card-text>

              <v-sheet
                v-if="!student.myself"
                color="white"
                class="d-flex flex-row justify-space-between pa-2 pr-4"
              >
                <v-textarea
                  height="80"
                  class="pl-4 pr-4"
                  v-model="student.comment"
                  filled
                  label="Your Feeback"
                  placeholder="Your overall feedback please..."
                >
                </v-textarea>

                <v-btn
                  color="primary"
                  elevation="2"
                  @click="setComment(student)"
                  :disabled='!student.writeMode'
                >
                  Save Comments
                </v-btn>
              </v-sheet>

            </v-card>
          </v-col>
        </v-row>
      </div>

  </div>
</template>

<script>
import { fetchReviewParameters, addProjectCriteriaScore, addProjectComment } from '../../api/course'

export default {
  components: {
  },
  data() {
    return {
      students: [],
      defaultSelected:1,
      reviewPhase: 0
    }
  },
  mounted(){
    this.fetchReviewParameters(1)
  },
  methods:{
    async fetchReviewParameters(reviewPhase){
      try{
        const apiData = await fetchReviewParameters({reviewPhase: reviewPhase })
        const cleanData = []

        if(!apiData || !apiData.data || apiData.data.length === 0){

          this.$toast.open({
            message: 'Please get in touch with the Admin. This review phase has not yet been defined.',
            type: 'info',
          });

          this.removeCourse()
          return;
        }

        for(let i = 0; i < apiData.data.length; i++){
          let apiRow = apiData.data[i]
          apiRow['criterias'] = JSON.parse(apiRow['criterias'])
          cleanData.push(apiRow)
        }
        
        this.students = cleanData
      }
      catch( e ){
        console.error(e)
      }
    },
    removeCourse(){
      this.students = []
    },
    criteriaDescription(description, myself){

      let desc = description ? description : '';

      if(myself){
        return desc
      }

      return desc.replaceAll('I ', 'He/She ').replaceAll(' my', ' his/her ')
    },
    async setMarks(teamMember, criteria, marks){

      if(!marks){
        return; //FIXME:: Later, make this more elegant.
      }

      try{
        await addProjectCriteriaScore({
          rollNumber: teamMember.rollNumber,
          marks: marks,
          reviewPhase: this.reviewPhase + 1,
          criteriaId: criteria.criteriaId
        })
      }
      catch( e ){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
        this.isProcessing = false
      }
    },
    async setComment(teamMember){
      if(!teamMember.comment || teamMember.comment.trim().length < 4){
        this.$toast.open({
          message: 'Please do fill in some meaningful comments. Thanks!',
          type: 'error',
        });
        return;
      }

      try{
        await addProjectComment({
          rollNumber: teamMember.rollNumber,
          comment: teamMember.comment,
          reviewPhase: this.reviewPhase + 1,
        });

        this.$toast.open({
          message: 'Thanks for your feedback!',
          type: 'success',
        });
      }
      catch( e ){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
        this.isProcessing = false
      }
    }
  },
}
</script>

